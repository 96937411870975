import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "プロダクト品質向上のためのフルスタック開発〜メドレーにおける機能ベース開発について〜",
  "date": "2016-08-26T05:47:02.000Z",
  "slug": "entry/2016/08/26/144702",
  "tags": ["medley"],
  "hero": "./2016_08_26.png",
  "heroAlt": "full stack"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`文責：徐　聖博（`}<a parentName="p" {...{
        "href": "https://www.target-darts.jp/shopdetail/000000000022/ct33/page1/recommend/"
      }}>{`ダーツプロ`}</a>{`）`}</p>
    <p>{`こんにちは！メドレーのダーツプロこと徐です。`}{` `}</p>
    <p>{`社会人歴・エンジニア歴 3 年目ながら、メドレーでは`}<strong parentName="p">{`口コミで探せる介護施設の検索サイト「`}<a parentName="strong" {...{
          "href": "https://www.kaigonohonne.com/"
        }}>{`介護のほんね`}</a>{`」の開発・運用を 1 人で担当`}</strong>{`しています。（インフラ整備からフロントエンド開発まで行っています）`}{` `}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160725/20160725165814.png",
        "alt": "f:id:yamadagenki:20160725165814p:plain",
        "title": "f:id:yamadagenki:20160725165814p:plain"
      }}></img></p>
    <p>{`現在筆者が一人で担当している介護施設の口コミサイトである「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」`}</p>
    <p>{`前職では Web ゲームのバックエンド開発（`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/PHP"
      }}>{`PHP`}</a>{`）を担当していて、メドレーに入った時点では`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`「インフラ？経験無いです…」`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`「フロントエンド？`}<a parentName="strong" {...{
            "href": "https://d.hatena.ne.jp/keyword/Javascript"
          }}>{`Javascript`}</a>{`で書くんですよね…？」`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`「`}<a parentName="strong" {...{
            "href": "https://d.hatena.ne.jp/keyword/Ruby%20on%20Rails"
          }}>{`Ruby on Rails`}</a>{`？`}<a parentName="strong" {...{
            "href": "https://d.hatena.ne.jp/keyword/Ruby"
          }}>{`Ruby`}</a>{`なら書くだけかけますけど…」`}</strong></li>
    </ul>
    <p>{`という状態でした。今回はそんな自分が、入社後半年で`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D5%A5%EB%A5%B9%A5%BF"
      }}>{`フルスタ`}</a>{`ックエンジニアとして`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`を 1 人で担当できるまでに至った、メドレーの開発体制について紹介します。`}</p>
    <h1>{`メドレーのプロダクト`}</h1>
    <p>{`メドレーには Web アプリケーションから`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/iOS"
      }}>{`iOS`}</a>{`のアプリケーションまで様々なプロダクトがあります。`}</p>
    <p>{`サーバーサイドに`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby%20on%20Rails"
      }}>{`Ruby on Rails`}</a>{`を用いるプロダクトを初め、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/PHP"
      }}>{`PHP`}</a>{`を使ったプロダクトもあり、構成はプロダクトにより様々です。フロントエンドでは、AngularJS や Mithril の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/MVC"
      }}>{`MVC`}</a>{`の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D5%A5%EC%A1%BC%A5%E0%A5%EF%A1%BC%A5%AF"
      }}>{`フレームワーク`}</a>{`を使用したり、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/CoffeeScript"
      }}>{`CoffeeScript`}</a>{`や Babel なる AltJS を用いて開発しており、幅広い技術を採用しています。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160610/20160610160409.png",
        "alt": "f:id:yamadagenki:20160610160409p:plain",
        "title": "f:id:yamadagenki:20160610160409p:plain"
      }}></img></p>
    <p>{`サーバーサイドを`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby%20on%20Rails"
      }}>{`Ruby on Rails`}</a>{`で書き、Mithril を使い SPA で作られている「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」。インフラは、Heroku を採用。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160610/20160610162818.png",
        "alt": "f:id:yamadagenki:20160610162818p:plain",
        "title": "f:id:yamadagenki:20160610162818p:plain"
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`では、メドレーで初となる`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/iOS"
      }}>{`iOS`}</a>{`アプリをリリース。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160610/20160610160403.png",
        "alt": "f:id:yamadagenki:20160610160403p:plain",
        "title": "f:id:yamadagenki:20160610160403p:plain"
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/PHP"
      }}>{`PHP`}</a>{`の爆速 Phalcon`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D5%A5%EC%A1%BC%A5%E0%A5%EF%A1%BC%A5%AF"
      }}>{`フレームワーク`}</a>{`を使用したオンライン病気事典「`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`」`}</p>
    <p>{`このように多種多様な技術を用いながら、どのプロダクトにおいてもメンバーはそれぞれインフラからフロントエンドまで幅広い開発をしています。しかし、もともと`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D5%A5%EB%A5%B9%A5%BF"
      }}>{`フルスタ`}</a>{`ックエンジニアだったという人は少なく、尖った強みを持ちながら、メドレーに入社後、徐々に対応範囲を広げてきたというケースが多いです。`}{` `}</p>
    <p>{`これは、メドレーにおける”機能ベース開発”という進め方が大きく関係しています。`}</p>
    <h1>{`機能ベース開発が、エンジニアを`}<a parentName="h1" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D5%A5%EB%A5%B9%A5%BF"
      }}>{`フルスタ`}</a>{`ックにする`}</h1>
    <p>{`メドレーでは、基本的に機能ベースで ISSUE ・タスクを切り分け、それぞれの ISSUE ・タスクを週のはじめのミーティングにおいて担当者を決めていきます。`}{` `}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160824/20160824092401.png",
        "alt": "f:id:yamadagenki:20160824092401p:plain",
        "title": "f:id:yamadagenki:20160824092401p:plain"
      }}></img></p>
    <p>{`それぞれ業務の幅が広いタスクがたくさんあり、週のはじめに担当を決めていく`}</p>
    <p>{`インフラやフロントエンドの得手不得手は各々ありますが、基本的には関係なくタスクが割り振られ（もしくは挙手制で）作業を進めていきます。`}</p>
    <p>{`「知識が足りない・全く経験がないタスクが回ってきた！(> <)」などという場合も、お互い協力・教えあいながらタスクをこなしていくので、経験のないチャレンジングなタスクに挙手できたりします。`}{` `}</p>
    <h1>{`システム全体を把握して開発することで、プロダクト全体の品質向上`}</h1>
    <p>{`このタスクベース開発体制を続けていると、システム全体の開発に携わることが多くなります。入社当時はバックエンドしか知識がなかったエンジニアも、開発する機能が増え、プロダクト全体の構成を把握する機会が増えるため、入社から半年〜1 年経つ頃には、1 つのサービスを一通りインフラからバックエンド、フロントエンドまで触ることになります。`}{` `}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20160824/20160824093922.png",
        "alt": "f:id:yamadagenki:20160824093922p:plain",
        "title": "f:id:yamadagenki:20160824093922p:plain"
      }}></img></p>
    <p>{`Elasticsearch の基盤整備から、DB の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%B9%A5%AD%A1%BC%A5%DE"
      }}>{`スキーマ`}</a>{`設計、バックエンド・フロントエンドの作りこみまで
全て一人で開発したジョブメドレーのトップ画面`}</p>
    <p>{`こうしてシステム全体を把握することで、エンジニアたちがそれぞれ他の分野を考慮したプログラムを書けるようになり、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/API"
      }}>{`API`}</a>{`の設計・ユーザインタラクション開発や、インフラ構成を配慮したキャッシュ機能開発など、一貫性をもった開発が可能となります。`}</p>
    <p>{`もちろんもともと、特定分野に強みを持つエンジニアが集まっていますので、それぞれ得意分野を活かしつつも、お互いの領域が理解できることで、社内のプロダクトの全体的な最終的な品質向上を実現できていると感じています。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`今回はメドレーにおける機能ベースの開発体制を紹介しました。`}</p>
    <p>{`メドレーでは、このようにどのプロダクトでも個人が機能ベースで開発をしていきます。そのため、入社時点で経験がない分野でも一年ほど開発に携わることで、一通りのインフラからフロントエンドまでの開発をこなせる知識がつき、僕も一人で 1 つのプロダクトを担当できるまでになりました。`}</p>
    <p>{`しかし、これは機能ベース開発だけのおかげではなく、様々な分野のとても気さくなシニアエンジニアが集まり、気軽に質問できる開発部の雰囲気も大いに関係していると思っています。僕自身この 1 年で、さまざまな分野に挑戦しましたが、各領域のプロがレクチャーしてくれることで、その分野に自信をもって取り組めるようになったと感じています。`}</p>
    <p>{`また、メドレーに集まるエンジニアは皆開発が大好きです。各々が日々技術に対して貪欲で、勉強が好き・開発が好きという個人の努力により、知らない困難なタスクも進んで開発した結果`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D5%A5%EB%A5%B9%A5%BF"
      }}>{`フルスタ`}</a>{`ックエンジニアになります。これが最終的なプロダクト品質へのこだわりになり、品質の高いプロダクトができているのだと思っています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D5%A5%EB%A5%B9%A5%BF"
      }}>{`フルスタ`}</a>{`ックな開発がしたい、システム全体考えた品質の高い開発をしたいような方は是非メドレーで一緒に働いてみませんか？`}</p>
    <p>{`勉強大好き、開発するのが大好き！ダーツが大好き！(？)というようなエンジニアをメドレーでは募集しています。`}{` `}</p>
    <p>{`ぜひ、よろしくお願いします！`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="株式会社メドレーの採用/求人一覧 - Wantedly" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.wantedly.com%2Fcompanies%2Fmedley%2Fprojects" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.wantedly.com/companies/medley/projects">www.wantedly.com</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      